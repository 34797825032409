import Animated, { Revealed } from 'components/animated'
import Box, { Card, Content, Flex, Grid, Section } from 'components/box'
import convertImageMap from 'helpers/convertImageMap'
import Emoji from 'components/emoji'
import Img from 'gatsby-image'
import IO from 'components/io'
import Layout from 'components/layout'
import PropTypes from 'prop-types'
import React from 'react'
import Text, { Heading, Lead } from 'components/text'
import { graphql } from 'gatsby'
import { ProductList } from 'components/sections/products'
import CallToAction from 'components/sections/cta'

const Home = ({ data }) => {
  const { page } = data
  const { hero, intentional } = page.images
  const icons = convertImageMap(page.icons)

  return (
    <Layout pageTitle={page.title}>
      <Section minHeight="105vh" pb={7} pt={6}>
        <Box
          position="fixed"
          width="100%"
          height="100%"
          top="0"
          left="0"
          zIndex={-1}
        >
          <Img
            alt={hero.title}
            draggable={false}
            style={{ height: '100%', opacity: 0.3333 }}
            fluid={hero.image.childImageSharp.fluid}
          />
        </Box>
        <Box
          backgroundImage="linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
          position="fixed"
          width="100%"
          height="100%"
          top="0"
          left="0"
          zIndex={-1}
        />
        <Content textAlign="center" mb={[4, 4, 5]}>
          <Heading
            as={Revealed}
            forwardedAs="h1"
            level={0}
            measure={['tight', 'narrow']}
            mx="auto"
          >
            Control distractions, <br />
            take back your time.
          </Heading>
          <Lead
            as={Revealed}
            delay={1}
            fontWeight="medium"
            measure="narrow"
            mx="auto"
            mt={3}
          >
            {page.description}
          </Lead>
        </Content>
      </Section>

      <Box position="relative" mb="-12rem" mt={-7} zIndex={1}>
        <Content>
          <ProductList />
        </Content>
      </Box>

      <Section bg="neutral-050" pt={7}>
        <Content my={[5, 5, 6]}>
          <Heading
            as={Revealed}
            forwardedAs="h2"
            level={2}
            measure="wide"
            mt={4}
          >
            Stop scrolling mindlessly
          </Heading>
          <Lead as={Revealed} forwardedAs="p" delay={1} measure="wide" mt={1}>
            Companies design their products to keep you hooked.
          </Lead>
          <IO>
            {({ isVisible, hasBeenVisible }) => (
              <Grid
                columns={[1, 2, 4]}
                gridGap={2}
                mt={[4, 4, 5]}
                textAlign={['left', 'center']}
              >
                <Card
                  as={Animated}
                  delay={0}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Cyclone']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Infinite scrolling
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Aiming to exploit our natural urge to finish what has been
                      started.
                    </Text>
                  </Flex>
                </Card>
                <Card
                  as={Animated}
                  delay={1}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Boom']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Disappearing stories
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Latching on our fear of missing out on something
                      ephemeral.
                    </Text>
                  </Flex>
                </Card>
                <Card
                  as={Animated}
                  delay={2}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Robot']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      AI-curated feed
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Optimizing for engagement rather than actual content
                      value.
                    </Text>
                  </Flex>
                </Card>
                <Card
                  as={Animated}
                  delay={3}
                  display="flex"
                  elevation={1}
                  flexDirection={['row', 'column']}
                  alignItems="center"
                  isVisible={isVisible || hasBeenVisible}
                >
                  <Emoji
                    alignSelf={['flex-start', 'center']}
                    size="emoji"
                    m={3}
                    mb={[3, -2]}
                    mr={[-2, 3]}
                    p={3}
                    {...icons['Monkey']}
                  />
                  <Flex flexDirection="column" p={3}>
                    <Heading level={4} lineHeight={2}>
                      Subconscious triggers
                    </Heading>
                    <Text color="neutral-400" as="p" mt={1}>
                      Using our habit-forming patterns against us.
                    </Text>
                  </Flex>
                </Card>
              </Grid>
            )}
          </IO>
        </Content>
      </Section>

      <Section bg="neutral-025">
        <Content my={[5, 5, 6]}>
          <Box position="relative" zIndex={1}>
            <Heading
              as={Revealed}
              forwardedAs="h2"
              level={2}
              measure="normal"
              mt={4}
            >
              Intentional by design
            </Heading>
            <Lead
              as={Revealed}
              forwardedAs="p"
              delay={1}
              measure="normal"
              mt={1}
              mb={[4, 4, 0]}
            >
              Our goal is to add intent by developing tools that put you back in
              control.
            </Lead>
          </Box>
          <Flex flexDirection={['column', 'column', 'row']} mx={-3}>
            <Box
              borderRadius={1}
              mx={3}
              width={['100%', '100%', '50%']}
              overflow={['visible', 'visible', 'hidden']}
              position={['static', 'static', 'relative']}
            >
              <Box
                bottom="0"
                height="100%"
                opacity={[0.25, 0.25, 1]}
                position="absolute"
                right="0"
                width="100%"
                zIndex={0}
              >
                <Img
                  alt={intentional.title}
                  draggable={false}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  fluid={intentional.image.childImageSharp.fluid}
                />
              </Box>
            </Box>
            <IO
              mx={3}
              width={['auto', 'auto', '50%']}
              position="relative"
              zIndex={1}
            >
              {({ isVisible, hasBeenVisible }) => (
                <Grid gridGap={3}>
                  <Card
                    as={Animated}
                    delay={1}
                    display="flex"
                    elevation={1}
                    isVisible={isVisible || hasBeenVisible}
                    maxWidth={[0, 'wide', 0]}
                  >
                    <Emoji
                      size="emoji"
                      px={3}
                      py={3}
                      m={3}
                      mr={0}
                      {...icons['Bulb']}
                    />
                    <Box p={3}>
                      <Heading level={4} lineHeight={2}>
                        Insights
                      </Heading>
                      <Text color="neutral-400" as="p" mt={1}>
                        See your own patterns so that you can follow up with a
                        meaningful plan of action.
                      </Text>
                    </Box>
                  </Card>
                  <Card
                    as={Animated}
                    delay={2}
                    display="flex"
                    elevation={1}
                    isVisible={isVisible || hasBeenVisible}
                    maxWidth={[0, 'wide', 0]}
                  >
                    <Emoji
                      size="emoji"
                      px={3}
                      py={3}
                      m={3}
                      mr={0}
                      {...icons['Warning']}
                    />
                    <Box p={3}>
                      <Heading level={4} lineHeight={2}>
                        Friction
                      </Heading>
                      <Text color="neutral-400" as="p" mt={1}>
                        Add a little bit of friction to clarify your intention.
                      </Text>
                    </Box>
                  </Card>
                  <Card
                    as={Animated}
                    delay={3}
                    display="flex"
                    elevation={1}
                    isVisible={isVisible || hasBeenVisible}
                    maxWidth={[0, 'wide', 0]}
                  >
                    <Emoji
                      size="emoji"
                      px={3}
                      py={3}
                      m={3}
                      mr={0}
                      {...icons['Nudge']}
                    />
                    <Box p={3}>
                      <Heading level={4} lineHeight={2}>
                        Thoughtful nudges
                      </Heading>
                      <Text color="neutral-400" as="p" mt={1}>
                        Know when you've been spending too much time or have
                        been distracting yourself too often.
                      </Text>
                    </Box>
                  </Card>
                  <Card
                    as={Animated}
                    delay={4}
                    display="flex"
                    elevation={1}
                    isVisible={isVisible || hasBeenVisible}
                    maxWidth={[0, 'wide', 0]}
                  >
                    <Emoji
                      size="emoji"
                      px={3}
                      py={3}
                      m={3}
                      mr={0}
                      {...icons['Barricade']}
                    />
                    <Box p={3}>
                      <Heading level={4} lineHeight={2}>
                        Limits
                      </Heading>
                      <Text color="neutral-400" as="p" mt={1}>
                        Set access limits to what you find distracting.
                      </Text>
                    </Box>
                  </Card>
                  <Card
                    as={Animated}
                    delay={5}
                    display="flex"
                    elevation={1}
                    isVisible={isVisible || hasBeenVisible}
                    maxWidth={[0, 'wide', 0]}
                  >
                    <Emoji
                      size="emoji"
                      px={3}
                      py={3}
                      m={3}
                      mr={0}
                      {...icons['Detective']}
                    />
                    <Box p={3}>
                      <Heading level={4} lineHeight={2}>
                        Privacy
                      </Heading>
                      <Text color="neutral-400" as="p" mt={1}>
                        Be rest assured that you're not being spied upon.
                      </Text>
                    </Box>
                  </Card>
                </Grid>
              )}
            </IO>
          </Flex>
        </Content>
      </Section>
      <CallToAction />
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Home

export const query = graphql`
  query HomepageQuery {
    page: homeJson {
      title
      description
      icons {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      images {
        hero {
          title
          image {
            childImageSharp {
              fluid(
                duotone: { highlight: "#FCFDFF", shadow: "#7B8794" }
                toFormat: JPG
                jpegProgressive: true
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        intentional {
          title
          image {
            childImageSharp {
              fluid(
                duotone: { highlight: "#FCFDFF", shadow: "#616E7C" }
                toFormat: JPG
                jpegProgressive: true
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
